<template>
  <div class="enterprise-wrap">
    <div class="bread-wrap">
      <span class="bread-title">个人中心/企业证件认证</span>
    </div>
    <div class="content">
      <p class="content-warning">
        <span class="spanwarning"
          >请提供正确的企业证件信息用于审核，系统不会泄漏您的证件信息</span
        >
      </p>
      <el-form
        label-width="165px"
        label-position="left"
        class="enterpriseform"
        ref="enterprise"
        :model="priseform"
        :rules="enterpriserules"
      >
        <el-row>
          <el-col :span="10">
            <el-form-item label="企业证件类型">
              <el-select
                class="selecttype"
                :disabled="true"
                v-model="selectvalue"
              >
                <el-option key="1" label="企业营业执照" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="营业执照附件" prop="license">
              <el-upload
                action="#"
                list-type="picture-card"
                :auto-upload="false"
                :on-change="fileschange"
                :on-remove="filesremove"
                :limit="1"
              >
                <i slot="default" class="el-icon-plus"></i>
              </el-upload>
              <span class="hint-text">附件大小不宜超过4M</span>
              <span class="hint-text-err">{{ ocrResult }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="企业名称" prop="companyname">
              <el-input
                :disabled="ocrLoading"
                placeholder="请输入企业名称"
                v-model="priseform.companyname"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="企业地址" prop="companyaddress">
              <el-input
                :disabled="ocrLoading"
                placeholder="请输入企业地址"
                v-model="priseform.companyaddress"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="社会统一信用代码" prop="USCI">
              <el-input
                :disabled="ocrLoading"
                placeholder="请输入社会统一信用代码"
                v-model="priseform.USCI"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row>
          <el-col>
            <p class="border-col"></p>
          </el-col>
        </el-row> -->
        <el-row>
          <el-col :span="10">
            <el-form-item label="法定代表人姓名" prop="bossname">
              <el-input
                :disabled="ocrLoading"
                placeholder="请输入法定代表人姓名"
                v-model="priseform.bossname"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="法定代表人身份证号码" prop="bossnumber">
              <el-input
                placeholder="请输入法定代表人身份证号码"
                v-model="priseform.bossnumber"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!--<el-row>
          <el-col :span="10">
            <el-form-item label="法定代表人手机号码" prop="bossphone">
              <el-input
                placeholder="请输入法定代表人手机号码"
                v-model="priseform.bossphone"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>-->
      </el-form>
      <div class="submit">
        <el-button @click="submit" type="primary" class="submitbtn"
          >提交认证</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import navigation from "../../../utils/navigation";
export default {
  data() {
    const validatecomname = function (rule, value, callback) {
      if (value.length == 0) {
        callback(new Error("请输入企业名称"));
      } else {
        callback();
      }
    };
    const validatecomaddress = function (rule, value, callback) {
      if (value.length == 0) {
        callback(new Error("请输入企业地址"));
      } else {
        callback();
      }
    };
    const validateUSCI = function (rule, value, callback) {
      if (value.length == 0) {
        callback(new Error("请输入社会统一信用代码"));
      } else {
        callback();
      }
    };
    const validatebossname = function (rule, value, callback) {
      if (value.length == 0) {
        callback(new Error("请输入法定代表人姓名"));
      } else {
        callback();
      }
    };
    const validatebossnumber = function (rule, value, callback) {
      if (value.length == 0) {
        callback(new Error("请输入法定代表人身份证号"));
      } else {
        callback();
      }
    };
    const validatebossphone = function (rule, value, callback) {
      if (value.length == 0) {
        callback(new Error("请输入法定代表人手机号码"));
      } else if (value.length != 11) {
        callback(new Error("手机号码格式不正确"));
      } else {
        callback();
      }
    };
    return {
      priseform: {
        companyname: "",
        companyaddress: "",
        USCI: "",
        license: "",
        bossname: "",
        bossnumber: "",
        bossphone: "",
      },
      enterpriserules: {
        companyname: [
          {
            required: true,
            trigger: "blur",
            validator: validatecomname,
          },
        ],
        companyaddress: [
          {
            trigger: "blur",
            // validator: validatecomaddress,
          },
        ],
        USCI: [
          {
            trigger: "blur",
            // validator: validateUSCI,
          },
        ],
        bossname: [
          {
            required: true,
            trigger: "blur",
            validator: validatebossname,
          },
        ],
        bossnumber: [
          {
            required: true,
            trigger: "blur",
            validator: validatebossnumber,
          },
        ],
        bossphone: [
          {
            required: true,
            trigger: "blur",
            validator: validatebossphone,
          },
        ],
        license: [
          {
            required: true,
            message: "请上传营业执照附件",
          },
        ],
      },
      selectvalue: "1",
      ocrResult: '',
      ocrLoading: false
    };
  },
  methods: {
    submit() {
      this.$refs.enterprise.validate((valid) => {
        if (valid) {
          let data = {
            company_name: this.priseform.companyname,
            business_license: this.priseform.license.raw,
            USCI: this.priseform.USCI,
            boss_name: this.priseform.bossname,
            boss_id_number: this.priseform.bossnumber,
            company_address: this.priseform.companyaddress,
            //boss_phone_number: this.priseform.bossphone,
          };
          this.$store
            .dispatch("personal/submitcertification", { ...data })
            .then((res) => {
              console.log("submitcertification: " + JSON.stringify(res));
              if (res.RetCode == 0) {
                navigation.navigateTo({
                  url: "enterconfirm",
                });
              } else {
                this.$message.warning(`提示：${res.Message}`)
              }
            });
        } else {
          return false;
        }
      });
    },
    fileschange(file, fileList) {
      this.priseform.license = file;

      this.ocrLoading = true;
      this.ocrResult = ''
      this.$store
          .dispatch("personal/businessLicenseOcr", {business_license: file.raw})
          .then((res) => {
            this.priseform.companyname = res.companyName
            this.priseform.bossname = res.bossName
            this.priseform.companyaddress = res.companyAddress
            this.priseform.USCI = res.usci
          })
          .catch(err => {
            this.ocrResult='营业执照识别失败，请手动输入相关信息或上传更加清晰的图片！'
          })
          .finally(() => {
            this.ocrLoading = false;
          });
    },
    filesremove(file, fileList) {
      console.log(file);
      this.priseform.license = "";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>